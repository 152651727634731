// const BASEURL = 'https://keiko.amicusbrain.com';
const BASEURL = '';

export default {
    detectIntent: BASEURL + '/api/intents/detect',
    detectIntentContext: BASEURL + '/api/intents/contextDetect',
    textToSpeechUrl: BASEURL + '/api/textToSpeech',
    speechToText: BASEURL + '/api/speechToText',
    homeHealthCareSearch: BASEURL + '/api/getHealthCareAgencies',
    homeHealthCareCount: BASEURL + '/api/getHealthCareCount',
    cogniRehabSerach: BASEURL +  '/api/getCogRehabitList',
    nursingHomeSearch: BASEURL + '/api/getNursingHomeDetails',
    nursingHomeSearchCount: BASEURL + '/api/getNursingHomeCount',
    getResourceCount : BASEURL + '/api/getResourceCount',
    getResource : BASEURL + '/api/getResource',
    physicianSearch: BASEURL + '/api/getPhysiciansDetails',
    physicianSearchCount: BASEURL + '/api/getPhysiciansCount',
    careGiverSearch: BASEURL + '/api/getCaregiverSupport',
    careGiverSearchCount: BASEURL + '/api/getCaregiverSupportCount',
    respiteServiceSearch: BASEURL + '/api/getRespiteServiceDetails',
    respiteServiceSearchCount: BASEURL + '/api/getRespiteServiceCount',
    elderCareSearch: BASEURL + '/api/getElderLaw',
    elderCareSearchCount: BASEURL + '/api/getElderCareCount',
    getZipcodeList: 'https://www.zipcodeapi.com/rest/js-iPl5aNwzCNBabrD0H1YGhx7eciZ8e4IuqnCbN30dqchVk39VA9uD9B0avF134HEe/radius.json/$zip_code/$distance/miles?minimal',
    getStateDtls: 'https://api.zippopotam.us/us/$zip_code',
    getDistance: 'https://www.zipcodeapi.com/rest/js-iPl5aNwzCNBabrD0H1YGhx7eciZ8e4IuqnCbN30dqchVk39VA9uD9B0avF134HEe/distance.json/$zipcode1/$zipcode2/miles',
    detectAnswer: BASEURL + '/api/answers/detect',
    careGiverGuideUrl: 'https://drive.google.com/file/d/13vaPnaZkzhR7LSrV35RZObtrZ5muSwZo/preview',
    communityGuideUrl: 'https://drive.google.com/file/d/1k-PYPOR_SN5UXEuoMBb6VDVOTza_JNkW/preview',
    patientGuideUrl: 'https://drive.google.com/file/d/1i_FfQFBPHiq-tRfUwoscBx3NdshZVSxG/preview',
    pdfUrl: 'https://drive.google.com/file/d/1UPKBRdg_zsUk1PvuP0YwENy-30ZNlJmx/view?usp=sharing',
    pdfUrlCommunity: 'https://drive.google.com/file/d/1DejhFqKakG7amqmhk2pqKxlnfKzYMUd0/preview',
    navAIDEurl: 'https://drive.google.com/file/d/1Bp_c5wKz2cS4G33CcXlhi2Q2rxmDgqVB/preview',
    orcaSpanishurl:'https://drive.google.com/file/d/1sup48NWghO2f5n5YIcv5Q9_uj4rgunYl/view?usp=sharing',
    orcaUrl: 'https://drive.google.com/file/d/1IwkYBSUOkG4lU7MgQvl8pYqQkdTmgvmN/view',
    navAIDESpanishurl: 'https://drive.google.com/file/d/1_g8mycHh7zNzGxF4kBqchOaOnt3w4CtK/preview',
    raQuestions: BASEURL + '/api/question/getNextQuestion',
    raGenAi:'https://python-modules-356905.ue.r.appspot.com/genai/get/resource',
    insuraceApi:'https://python-modules-356905.ue.r.appspot.com/get/inusrance/list'
};



// js-nhnNghaJoeISCQpdgkEzhApfv9Qk86jTDuP0Wtwo0uQP4nxsxQZl6QkCkdkjV74i
// js-rCR2sSmBxqfumayRSNl3T0Mxr3rxstAU7BmHvzc3TSmBT7fo109uLYDB4iYvb6GF
